<template>
  <div class="page-container">
    <div class="header-wrap row">
      <div class="col-lg-2">
        <h2 class="ml-2">{{ page_title }}</h2>
      </div>
      <div class="col-lg-10 text-lg-right">
        <div class="row">
          <div class="col-lg-3">
            <select
              name="status"
              id=""
              class="form-control w-100"
              @change="init(1)"
              v-model="status_filter"
            >
              <option :value="null">Select Status</option>
              <option value="verified">Verified</option>
              <option value="pending">Unverified</option>
              <option value="all">All</option>
            </select>
          </div>
          <div class="col-lg-3">
            <select
              name="state"
              id=""
              class="form-control w-100"
              @change="init(1)"
              v-model="state_filter"
            >
              <option :value="null">Select State</option>
              <option
                :value="state.public_id"
                v-for="state in states"
                :key="state.id"
              >
                {{ state.name }}
              </option>
            </select>
          </div>
          <div class="col-lg-3">
            <select
              name="type"
              id=""
              class="form-control w-100"
              @change="init(1)"
              v-model="filter"
            >
              <option value="0">Select Type</option>
              <option value="artist">Artist</option>
              <option value="user">Supporter</option>
            </select>
          </div>
          <div class="col-lg-3 mt-2 mt-lg-0">
            <form
              @submit.prevent="init(1, true)"
              class="w-100 float-lg-right mb-2 mb-lg-0"
            >
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="search"
                  placeholder="Search"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-success rapland text-white btn-l"
                    type="button"
                    @click="init()"
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive table-responsive-data2 user_list_table">
      <table class="table table-data2">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Image</th>
            <th>Email</th>
            <th>State</th>
            <th>Type</th>
            <th>Paid/Free</th>
            <th class="text-right" style="padding-right: 68px">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="users.length == 0">
            <td>No Users Available</td>
          </tr>
          <tr
            v-for="(user, index) in users"
            :key="index"
            class="border-bottom"
            v-else
          >
            <td>{{ user.id }}</td>
            <!-- <td>{{ index + 1 }}</td> -->
            <td>{{ user.member_name }}</td>
            <td>
              <a :href="raplandUrl + '' + user.slug" target="_blank">
                <img
                  onerror="this.onerror=null;this.src=document.getElementById('defaultImage').src;"
                  :src="storageUrl + user.image"
                  :alt="user.member_name"
                  v-if="user.image"
                  class="avtar-image"
                />
                <img
                  src="@/assets/images/default.png   "
                  class="avtar-image"
                  v-else
                />
              </a>
            </td>
            <td>{{ user.email }}</td>
            <td>{{ user.state.name }}</td>
            <td class="text-capitalize">
              {{ user.type == "user" ? "supporter" : user.type }}
            </td>
            <td class="text-capitalize">{{ user.subscription_type }}</td>
            <td class="text-right">
              <div class="btn-group">
                <button
                  class="btn btn-warning rapland text-white cursor-pointer"
                  @click="editUser(user)"
                >
                  Edit
                </button>
                <button
                  class="btn btn-danger"
                  type="button"
                  @click="showDeleteModal(user.id, index)"
                >
                  Delete
                </button>
              </div>
            </td>
          </tr>
          <tr v-if="totalPage > 1">
            <td colspan="5">
              <nav>
                <ul class="pagination">
                  <li
                    :class="{
                      'page-item': true,
                      active: this.currentPage === p,
                    }"
                    v-for="p in totalPage"
                    :key="p"
                  >
                    <a
                      href="JavaScript:void(0);"
                      class="page-link"
                      @click="init(p)"
                    >
                      {{ p }}
                    </a>
                  </li>
                </ul>
              </nav>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        :class="{
          'child_info_wrapper overflow-auto': true,
          'right-o': showEditUser,
        }"
      >
        <div class="header_wrapper">
          <h2 class="pl-3 pt-0">Edit User</h2>
        </div>

        <span
          class="cancel-button h2 pr-3 pt-3 cursor-pointer"
          @click="showEditUser = false"
        >
          <i class="fas fa-times text-primary"></i>
        </span>

        <div class="container">
          <Form
            @submit="submitForm"
            :validation-schema="validationSchema"
            v-slot="{ errors }"
          >
            <div class="form-group row" name="name">
              <div class="col-12 col-md-2">Name</div>
              <div class="col-sm-10">
                <Field
                  type="text"
                  name="name"
                  :class="{ 'form-control': true, 'is-invalid': errors.name }"
                  placeholder="Name"
                  v-model="user.member_name"
                />
                <ErrorMessage name="name" class="invalid-feedback" />
              </div>
            </div>
            <div class="form-group row" name="email">
              <label class="col-12 col-md-2">Email</label>
              <div class="col-sm-10">
                <Field
                  type="email"
                  name="email"
                  readonly
                  :class="{ 'form-control': true, 'is-invalid': errors.name }"
                  placeholder="Name"
                  v-model="user.email"
                />
                <ErrorMessage name="email" class="invalid-feedback" />
              </div>
            </div>
            <div class="form-group row" name="password">
              <label class="col-12 col-md-2">Password</label>
              <div class="col-sm-10">
                <Field
                  type="text"
                  name="password"
                  :class="{
                    'form-control': true,
                    'is-invalid': errors.password,
                  }"
                  placeholder="Password"
                  v-model="newPassword"
                />
                <ErrorMessage name="password" class="invalid-feedback" />
              </div>
            </div>
            <div class="form-group row" name="type">
              <label class="col-12 col-md-2">Type</label>
              <div class="col-sm-10">
                <Field
                  v-model="user.type"
                  name="type"
                  id="type"
                  :class="{ 'form-control': true, 'is-invalid': errors.type }"
                  as="select"
                >
                  <option value="">Select Type</option>
                  <option value="user">Supporter</option>
                  <option value="artist">Artist</option>
                </Field>
                <ErrorMessage name="type" class="invalid-feedback" />
              </div>
            </div>
            <div class="form-group row" name="state">
              <label class="col-12 col-md-2">State</label>
              <div class="col-sm-10">
                <Field
                  v-model="user.state_public_id"
                  name="state"
                  id="state_id"
                  class="border-2 border-black rounded py-1"
                  :class="{ 'form-control': true, 'is-invalid': errors.state }"
                  as="select"
                >
                  <option value="" selected>Select State</option>
                  <option
                    :value="state.public_id"
                    v-for="state in states"
                    :key="state.id"
                  >
                    {{ state.name }}
                  </option>
                </Field>
                <ErrorMessage name="state" class="invalid-feedback" />
              </div>
            </div>
            <div class="form-group row" name="blast">
              <label class="col-12 col-md-2">Blast</label>
              <div class="col-sm-10">
                <Field
                  type="text"
                  name="blast"
                  :class="{ 'form-control': true, 'is-invalid': errors.blast }"
                  placeholder="Blast"
                  v-model="user.blast"
                />
                <ErrorMessage name="blast" class="invalid-feedback" />
              </div>
            </div>
            <div class="form-group row" name="instagram">
              <label class="col-12 col-md-2">Instagram</label>
              <div class="col-sm-10">
                <Field
                  type="text"
                  name="instagram"
                  :class="{
                    'form-control': true,
                    'is-invalid': errors.instagram,
                  }"
                  placeholder="Instagram"
                  v-model="user.instagram"
                />
                <ErrorMessage name="instagram" class="invalid-feedback" />
              </div>
            </div>
            <div class="form-group row" name="youtube">
              <label class="col-12 col-md-2">Youtube</label>
              <div class="col-sm-10">
                <Field
                  type="text"
                  name="youtube"
                  :class="{
                    'form-control': true,
                    'is-invalid': errors.youtube,
                  }"
                  placeholder="Youtube"
                  v-model="user.youtube"
                />
                <ErrorMessage name="youtube" class="invalid-feedback" />
              </div>
            </div>
            <div class="form-group row" name="soundcloud">
              <label class="col-12 col-md-2">Soundcloud</label>
              <div class="col-sm-10">
                <Field
                  type="text"
                  name="soundcloud"
                  :class="{
                    'form-control': true,
                    'is-invalid': errors.soundcloud,
                  }"
                  placeholder="Soundcloud"
                  v-model="user.soundcloud"
                />
                <ErrorMessage name="soundcloud" class="invalid-feedback" />
              </div>
            </div>
            <div class="form-group row" name="image">
              <label class="col-12 col-md-2">Image</label>
              <div class="col-sm-10">
                <Field
                  type="file"
                  name="image"
                  :class="{ 'is-invalid': errors.image }"
                  rules="required|image"
                  v-model="newImage"
                  @change="onFileChange"
                />
                <ErrorMessage name="image" class="invalid-feedback" />
                <div class="image-wrapper mt-3" v-if="previewImageUrl">
                  <img
                    :src="previewImageUrl"
                    onerror="this.onerror=null;this.src=document.getElementById('defaultImage').src;"
                  />
                  <div class="icon-wrapper" @click="deletePreviewImage()">
                    <i class="fas fa-times"></i>
                  </div>
                </div>
                <div class="image-wrapper mt-3" v-else>
                  <img
                    :src="storageUrl + user.image"
                    onerror="this.onerror=null;this.src=document.getElementById('defaultImage').src;"
                    :alt="user.member_name"
                    v-if="user.image"
                  />
                  <img src="@/assets/images/default.png" v-else />
                </div>
              </div>
            </div>
            <div class="form-group row" name="subscription_type">
              <label class="col-12 col-md-2">Subscription Type</label>
              <div class="col-sm-10">
                <Field
                  v-model="user.subscription_type"
                  name="subscription_type"
                  id="subscription_type"
                  :class="{
                    'form-control': true,
                    'is-invalid': errors.subscription_type,
                  }"
                  as="select"
                >
                  <option value="">Select Subscription Type</option>
                  <option value="free">Free</option>
                  <option value="paid">Paid</option>
                </Field>
                <ErrorMessage
                  name="subscription_type"
                  class="invalid-feedback"
                />
              </div>
            </div>
            <!-- <div class="form-group row" name="subscription_status">
              <label class="col-12 col-md-2">Status Type</label>
              <div class="col-sm-10">
                <Field
                  v-model="user.subscription_status"
                  name="subscription_status"
                  id="subscription_status"
                  :class="{
                    'form-control': true,
                    'is-invalid': errors.subscription_status,
                  }"
                  as="select"
                >
                  <option value="">Select Subscription Status Type</option>
                  <option value="active">Active</option>
                  <option value="in_active">Inactive</option>
                </Field>
                <ErrorMessage
                  name="subscription_status"
                  class="invalid-feedback"
                />
              </div>
            </div> -->
            <div class="form-group row" name="account_status">
              <label class="col-12 col-md-2">Account Status</label>
              <div class="col-sm-10">
                <Field
                  v-model="user.status"
                  name="account_status"
                  id="account_status"
                  class="form-control"
                  as="select"
                >
                  <option value="">Select Account Status Type</option>
                  <option value="verified">Active</option>
                  <option value="blocked">Inactive</option>
                </Field>
              </div>
            </div>
            <div class="form-group row" name="trial_ends_at">
              <label class="col-12 col-md-2"
                >Ends Date</label
              >
              <div class="col-sm-10">
                <Datepicker
                  :class="{
                    'form-control ': true,
                    'is-invalid': errors.trial_ends_at,
                  }"
                  name="trial_ends_at"
                  v-model="user.trial_ends_at"
                  :clearable="false"
                  :format="'yyyy-MM-dd'"
                />
                <!-- <Field
                  v-model="user.trial_ends_at"
                  type="date"
                  name="trial_ends_at"
                  :class="{
                    'form-control ': true,
                    'is-invalid': errors.trial_ends_at,
                  }"
                  placeholder="trial_ends_at"
                /> -->
                <ErrorMessage name="trial_ends_at" class="invalid-feedback" />
              </div>
            </div>
            <div class="pt-3 text-right">
              <button
                class="btn btn-success ml-2"
                type="submit"
                @click="submitForm"
              >
                <span>Save</span>
              </button>
            </div>
          </Form>
        </div>
      </div>

      <!-- Modal -->
      <div class="modal" :class="{ 'd-block': showDltModal }">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <p>Are you sure want to delete selected user ?</p>
            </div>
            <div class="modal-footer d-flex justify-content-between">
              <button
                type="button"
                class="btn boh1 btn-primary"
                @click="showDltModal = false"
              >
                CLOSE
              </button>
              <button
                type="button"
                class="btn boh2 btn-danger"
                @click="deleteUser(dltUser.id, dltUser.index)"
              >
                DELETE
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- delete faq ends -->
    </div>
  </div>
</template>

<script>
import API from "../../../api/http";
import { useToast } from "vue-toastification";
import { Form, Field, ErrorMessage } from "vee-validate";
import Datepicker from "vue3-datepicker";
import * as yup from "yup";

export default {
  name: "UserList",
  components: {
    Form,
    Field,
    ErrorMessage,
    Datepicker,
  },
  data() {
    const schema = yup.object().shape({
      member_name: yup.string().required().min(2).nullable(),
      email: yup.string().required().email(),
      password: yup.string().min(6).nullable(),
      state: yup.string().required(),
      type: yup.string().required(),
      blast: yup.string().nullable(),
      instagram: yup.string().nullable(),
      youtube: yup.string().nullable(),
      soundcloud: yup.string().nullable(),
    });

    return {
      page_title: "Users",
      url: "user",
      validationSchema: schema,
      loading: true,
      search: null,
      toast: useToast(),
      users: [],
      currentPage: 1,
      totalPage: null,
      showChildren: false,
      children: [],
      storageUrl: process.env.VUE_APP_MEDIA_BASE_URL,
      showDltModal: false,
      dltUser: {
        id: null,
        index: null,
      },
      filter: 0,
      showEditUser: false,
      user: {},
      states: [],
      previewImageUrl: null,
      newImage: null,
      newPassword: null,
      raplandUrl: process.env.VUE_APP_RAPLAND_URL,
      state_filter: null,
      status_filter: null,
    };
  },
  created() {
    this.init(this.currentPage);
  },
  methods: {
    init(page) {
      console.log(page);
      var url = this.url + "?page=" + page;
      this.currentPage = page;

      if (this.search) {
        url += "&search=" + this.search;
      }
      if (this.filter != "0") {
        url += "&type=" + this.filter;
      }
      if (this.state_filter) {
        url += "&state_filter=" + this.state_filter;
      }
      if (this.status_filter) {
        url += "&status=" + this.status_filter;
      }
      API.get(url).then((response) => {
        this.loading = false;
        this.users = response.users.data;
        this.states = response.states;
        this.totalPage = response.users.last_page;
      });
    },
    onFileChange(e) {
      const file = e.target.files[0];

      console.log(e);
      this.previewImageUrl = URL.createObjectURL(file);
      this.imageToBase64(file);
    },
    imageToBase64(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.newImage = reader.result;
      };
    },
    deletePreviewImage() {
      this.previewImageUrl = null;
    },
    showDeleteModal(id, index) {
      this.showDltModal = true;
      this.dltUser.id = id;
      this.dltUser.index = index;
    },
    editUser(user) {
      this.user = user;
      this.showEditUser = true;
    },
    submitForm() {
      var postData = this.user;
      postData._method = "PUT";
      if (this.newImage) {
        postData.new_image = this.newImage;
      }
      if (this.newPassword) {
        postData.new_password = this.newPassword;
      }

      API.post("user/" + this.user.id, postData).then((response) => {
        this.user = response.user;
        this.toast.success(response.message);
        this.showEditUser = false;
        this.init(1);
      });
    },
    deleteUser(id, index) {
      API.post("user/" + id, { _method: "DELETE" }).then((response) => {
        this.showDltModal = false;
        this.users.splice(index, 1);
        this.toast.success(response.message);
      });
    },
  },
};
</script>
