<template>
    <div class="page-container">
        <div class="header-wrap row">
            <div class="col-lg-6">
                <h2 class="ml-2">{{ page_title }}</h2>
            </div>
            <div class="col-lg-6 text-lg-right">
                <form @submit.prevent="init(1,true)" class="w-100 float-lg-right mb-2 mb-lg-0 ">
                    <div class="input-group row mr-0">
                        <div class="col-md-6 col-lg-5 offset-lg-2 mb-2 mb-md-0 ">
                            <select class="w-100 form-control mr-3" name="cloth_type" id="" v-model="type" @change="filterData()">
                                <option value="0">Cloth Types</option>
                                <option v-for="(type,index) in types" :key="index" :value="type.id">{{ type.name }}</option>
                            </select>
                        </div>
                        <div class="col-md-6 col-lg-5 ">
                            <select class="w-100 form-control" name="cloth_type" id="" v-model="theme" @change="filterData()">
                                <option value="0">Cloth Theme</option>
                                <option v-for="(theme,index) in themes" :key="index" :value="theme.id">{{ theme.name }}</option>
                            </select>
                        </div>						
                    </div>
                </form>
            </div>
        </div>
        <div class="table-responsive table-responsive-data2 user_list_table">
            <table class="table table-data2">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Image</th>
                        <th>Type</th>
                        <th>Theme</th>
                        <th class="text-right">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- <template v-if="loading">
                        <tr>
                            <td colspan="5">Please Wait...</td>
                        </tr>
                        </template> -->
                    <template v-if="!loading && collections.length == 0">
                        <tr>
                            <td colspan="5">No Data Available</td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr v-for="(collection,index) in collections" :key="index" class="tr-shadow">
                            <td>{{ index+1 }}</td>
                            <td>
                                <img :src="storagePath + collection.image" class="collection-image">
                            </td>
                            <td v-if="collection.type">
                                {{ collection.type.name }}
                            </td>
                            <td v-else>--</td>
                            <td v-if="collection.theme">
                                {{ collection.theme.name }}
                            </td>
                            <td v-else>--</td>
                            <td class="text-right">
                                <div class="btn-group">
                                    <button class="btn boh1 text-white btn-primary cursor-pointer" @click="setCollection(collection)">Edit</button>
                                    <button class="btn boh2 btn-danger" type="button" @click="setDelete(collection.id,index)">Delete</button>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="totalPage > 1">
                            <td colspan="5">
                                <nav>
                                    <ul class="pagination">
                                        <li :class="{'page-item': true, 'active' : this.currentPage === p }" v-for="p in totalPage" :key="p">
                                            <a href="JavaScript:void(0);" class="page-link" @click="init(p)"> {{ p }} </a>
                                        </li>
                                    </ul>
                                </nav>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
            <div :class="{'child_info_wrapper' : true, 'right-o' : editCollection}">
                <div class="header_wrapper">
                    <h2 class="pl-3 pt-3">
                        Edit Collection
                    </h2>
                </div>
                <span class="cancel-button h2 pr-3 pt-3 cursor-pointer" @click="editCollection = false">
                    <i class="fas fa-times text-primary"></i>
                </span>
                <div class="container">
                    <div class="row w-100 mx-auto">
                        <Form @submit="submitForm" :validation-schema="validationSchema" v-slot="{ errors }" class="w-100 mt-4">
                            <div class="form-group row" name="type">
                                <label class="col-12 col-md-2">Clothe Type</label>
                                <div class="col-sm-10">
                                    <Field v-model="collection.cloth_type_id" name="type" id="type" class="border-2 border-black rounded py-1" 
                                        :class="{'form-control':true,'is-invalid': errors.type }" as="select">
                                        <option value="" selected>Select Type</option>
                                        <option :value="type.id" v-for="(type,index) in types" :key="index">
                                            {{ type.name }}
                                        </option>
                                    </Field>
                                    <ErrorMessage name="type"  class="invalid-feedback" />
                                </div>
                            </div>
                            <div class="form-group row" name="theme">
                                <label class="col-12 col-md-2">Clothe Theme</label>
                                <div class="col-sm-10">
                                    <Field v-model="collection.cloth_theme_id" name="theme" id="theme" class="border-2 border-black rounded py-1" 
                                        :class="{'form-control':true,'is-invalid': errors.theme }" as="select">
                                        <option value="" selected>Select Theme</option>
                                        <option :value="theme.id" v-for="(theme,index) in themes" :key="index">
                                            {{ theme.name }}
                                        </option>
                                    </Field>
                                    <ErrorMessage name="theme"  class="invalid-feedback" />
                                </div>
                            </div>
                            <div class="form-group row" name="url">
                                <label class="col-12 col-md-2">Url</label>
                                <div class="col-sm-10">
                                    <Field type="text" name="url" id="url"
                                        :class="{'form-control':true,'is-invalid': errors.url}" 
                                        placeholder="Url" v-model="collection.url" />
                                    <ErrorMessage name="url" class="invalid-feedback" />
                                </div>
                            </div>
                            <div class="form-group row" name="orientation">
                                <label class="col-12 col-md-2">Orientation</label>
                                <div class="col-sm-10 d-flex align-items-center">
                                    <div class="form-check-inline">
                                        <Field name="orientation" type="radio" value="horizontal" v-model="collection.orientation" 
                                          :class="{'form-check-input mr-2':true,'is-invalid': errors.orientation}"/>
                                        <span class="mr-2 d-flex align-items-center"> Horizontal </span>
                                        <Field name="orientation" type="radio" value="vertical" v-model="collection.orientation" 
                                          :class="{'form-check-input mr-2':true,'is-invalid': errors.orientation}"/>                                          
                                        <span class="mr-2"> Vertical </span>
                                        <ErrorMessage name="orientation" class="invalid-feedback"></ErrorMessage>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row" name="image">
                                <label class="col-12 col-md-2">Image</label>
                                <div class="col-sm-10">
                                    <Field type="file" name="image" id="image" rules="required|image"
                                        :class="{'is-invalid': errors.image}" 
                                        placeholder="Url" v-model="collection.new_image" 
                                        @change="onFileChange"
                                    />
                                    <ErrorMessage name="image" class="invalid-feedback" />
                                </div>
                            </div>
                            <div class="row">
                              <div class="col-10 offset-2">
                                <div class="image-wrapper" v-if="previewImageUrl">
                                  <img :src="previewImageUrl">
                                  <div class="icon-wrapper" @click="deletePreviewImage()">
                                    <i class="fas fa-trash"></i>
                                  </div>                                  
                                </div>
                                <div class="image-wrapper" v-else>
                                  <img :src="storagePath + collection.image">
                                  <!-- <div class="icon-wrapper" >
                                    <i class="fas fa-trash"></i>
                                  </div> -->
                                </div>
                              </div>
                            </div>
                            <div class="pt-2 text-right">
                                <button class="btn btn-success ml-2" type="submit">
                                <span v-if="!submitProcessing">Save</span>
                                <span v-else>Please Wait...</span>
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" :class="{  'd-block' : deleteModal }">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <p>Are you sure want to delete selected clothe collection ?</p>
                    </div>
                    <div class="modal-footer d-flex justify-content-between">
                        <button type="button" class="btn boh1 btn-primary" @click="deleteModal = false">CLOSE</button>
                        <button type="button" class="btn boh2 btn-danger" @click="deleteCollection(dltCollection.id,dltCollection.index)">DELETE</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- delete faq ends -->
    </div>
</template>

<script>
import API from "../../../api/http";
import { useToast } from "vue-toastification";
import { Form, Field, ErrorMessage  } from 'vee-validate';
import * as yup from 'yup';
import { serialize } from 'object-to-formdata'

export default {
	name: 'ClotheCollection',
	components: {
		Form,Field,ErrorMessage,
	},
	data(){
		const schema = yup.object().shape({
			type: yup.string().required().nullable(),
			theme: yup.string().required().nullable(),
			url: yup.string().required().url().nullable(),
			orientation: yup.string().required().nullable(),
			image: yup.mixed().required()
		});

		return {
			page_title: 'Clothe Collection Management',
			url: 'collection',
			loading: true,
			search: null,
			toast: useToast(),
			collections: [],
			currentPage: 1,
			totalPage: null,
			editCollection: false,
			collection: {
				cloth_type_id: null,
				cloth_theme_id: null,
				image: null,
				url: null,
				orientation: null,
				new_image: null,
			},
			dltCollection: {
				id: null,
				index: null
			},
			submitProcessing: false,
			type: 0,
			theme: 0,
			types: [],
			themes: [],
			validationSchema: schema,
			previewImageUrl: null,
			deleteModal: false,
			storagePath: process.env.VUE_APP_MEDIA_BASE_URL,
		}
	},
	created(){
		this.init(this.currentPage);
	},
	methods: {
		init(page){
			// console.log(page);
			var url = this.url + '?page=' + page;
			url += (this.type) ? '&type_id=' + this.type : '';
			url += (this.theme) ? '&theme_id=' + this.theme : '';

			this.currentPage = page;
			API.get(url).then((response) => {
				this.loading = false;
				this.collections = response.collections.data;
				this.types = response.clothe_types;
				this.themes = response.clothe_themes;
				this.totalPage = response.collections.last_page;
			})
		},
		filterData(){
			this.init(this.currentPage);
		},
		onFileChange(e) {
			const file = e.target.files[0];
			this.previewImageUrl = URL.createObjectURL(file);
			this.imageToBase64(file);
		},
		imageToBase64 (file) {  
			var reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => {
				this.collection.new_image = reader.result;
			}
		},
		submitForm(){
		// console.log(this.collection)
			console.log('submit form')
			var postData = this.collection;
			var formData;
			const options = {
				indices: true
			}
			delete postData.theme;
			delete postData.type;
			delete postData.updated_at;
			delete postData.created_at;
			postData._method = "PUT";
			formData = serialize(postData, options);

			API.post('collection/' + this.collection.id, formData ,{ 'Content-Type': 'multipart/form-data' })
			.then((response) => {
				this.toast.success(response.message);
				this.collection = response.collection;
				this.editCollection = false;
				this.init(1);
			})
		},
		deletePreviewImage(){
			this.previewImageUrl = null;
		},
		setCollection(collection){
			this.editCollection = true;
			this.collection = collection;
		},
		setDelete(id,index){
			this.dltCollection.id = id;
			this.dltCollection.index = index;
			this.deleteModal = true;
		},
		deleteCollection(id,index){
			API.post('collection/' + id ,{_method: 'DELETE'})
			.then((response) => {
				this.deleteModal = false;
				this.collections.splice(index, 1)
				this.toast.success(response.message);
			})
		},
	}
}
</script>
