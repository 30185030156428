<template>
    <div class="page-container">
        <div class="header-wrap row">
            <div class="col-lg-2">
                <h2 class="ml-2">Transaction</h2>
            </div>
            <div class="col-lg-10 text-lg-right">
                <div class="row float-right">
                    <div class="m-2">
                        <h5 class="text-right">Available Bal :- ${{availableBal}}</h5>
                    </div>
                    <div class="text-right">
                        <h5 class="btn btn-warning form-inline rapland text-white my-auto h6 text-decoration-none text-right mr-3"
                            @click="withdrawalAdminMoney">Withdrawal</h5>
                    </div>

                </div>
            </div>
        </div>
        <!-- <div>
            <div class="text-right">
                <h5 class="btn btn-warning form-inline rapland text-white my-auto h6 text-decoration-none text-right"
                    @click="withdralAdminMoney">withdrawal</h5>
            </div>
        </div>
        <div class="mt-2">
            <h5 class="text-right">Available Bal :- ${{availableBal}}</h5>
        </div> -->


        <div class="table-responsive table-responsive-data2 user_list_table">
            <table class="table table-data2">
                <thead>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Image</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Created</th>
                </thead>
                <tbody>
                    <tr v-for="(artist,index) in this.artistDatalist " :key="index">

                        <td>{{artist.id}}</td>
                        <td>{{artist.artists.member_name}}</td>
                        <td>
                            <img :src="storageUrl + artist.artists.image" :alt="artist.artists.member_name"
                                v-if="artist.artists.image" />
                            <img src="@/assets/images/default.png" class="avtar-image" v-else />
                            {{artist.artists.image}}
                        </td>
                        <td>${{artist.amount.toFixed(2)}}</td>
                        <td style="text-transform: capitalize;">{{setStatus(artist.payload).status}}</td>
                        <td>{{setDate(artist.created_at)}}</td>

                    </tr>
                </tbody>
            </table>


        </div>
    </div>


</template>

<script>
import API from '../../../api/api'



export default {
    name: 'Transaction',
    data() {
        return {
            artistDatalist: [],
            availableBal: 0,
        }
    },
    methods: {
        transaction() {
            let url = "transaction-history"
            API.get(url).then((response) => {
                this.artistDatalist = response.data.transactions.data;

                this.availableBal = response.data.available_balance;
                this.availableBal = this.availableBal.toFixed(2);


            })
        },
        setDate(time) {
            time = time.split('T')[0];

            return time;
        },
        setStatus(data) {
            let stat = JSON.parse(data)
            return stat
        },
        withdrawalAdminMoney() {
            console.log("admin money");
            let url = "creat-payout"
            API.post(url).then((response) => {
                console.log(response)
            }).catch((reject) => {
                console.log(reject)
            });

        },

    },
    created() {
        this.transaction();

    }
}

</script>