<template>
  <div class="page-container">
    <div class="header-wrap row border-bottom mx-auto">
      <div class="col-6"><h2 class="">{{ page_title }}</h2></div>
      <div class="col-6 text-right">
        <form @submit.prevent="init(1,true)" class="w-75 float-right">
          <div class="input-group">
            <input type="text" class="form-control" v-model="search" placeholder="Search">
            <div class="input-group-append">
              <button class="btn btn-success boh1 btn-l" type="button" @click="init()">Search</button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <Form class="row mx-auto" @submit="submitFaq" :validation-schema="validationSchema" v-slot="{ errors }" ref="form">
        <div class="col-8">
            <div class="row py-2 h-100">
                <div class="col-12 h-100">
                    <Field type="text" name="question" 
                        :class="{'form-control w-100 mb-2':true,'is-invalid': errors.question }" 
                        placeholder="Question" v-model="faq.question" />
                    <ErrorMessage name="question" class="invalid-feedback" />
                    <Field type="text" name="answer" 
                        :class="{'form-control w-100':true,'is-invalid': errors.answer }" 
                        placeholder="Answer" v-model="faq.answer" />
                    <ErrorMessage name="answer" class="invalid-feedback" />
                </div>
            </div>
        </div>
        <div class="col-2 d-flex justify-content-center align-items-center">
            <button class="btn btn-primary boh3 text-white w-50 justify-content-center" type="submit">
                Add New
            </button>
        </div>
        <div class="col-2 d-flex justify-content-center align-items-center">
            <router-link :to="{ name: 'FaqOrdering' }" class="btn btn-primary boh3 text-white w-50 justify-content-center" >
                Order FAQ
            </router-link>
        </div>
    </Form>

    <div class="mt-4 px-3">
        <div class="row mx-auto bg-light py-2 rounded mt-3" v-for="(faq,index) in faqs" :key="index">
            <div class="col-12" @click="showDiv(index)">
                <div class="row w-100">
                    <div class="col-1 font-weight-bold" style="padding-top: 9px;">
                        <label class="text-dark">Question:</label>
                    </div>
                    <div class="col-8 pr-0">
                        <div class="input-group h-100">
                            <input :readonly="!editThis" type="text" aria-describedby="basic-addon2" :class="(index == showDivIndex && editThis) ? 'cursor-pointer form-control' : 'h-100 bg-white cursor-pointer form-control border-right-0'" v-model="faq.question">
                            <div class="input-group-append">
                                <span v-if="index == showDivIndex && !editThis" class="input-group-text bg-white border-left-0 border" id="basic-addon2"><i class="fas fa-caret-up"></i></span>
                                <span v-if="index != showDivIndex" class="input-group-text bg-white border-left-0 border" id="basic-addon2"><i class="fas fa-caret-down"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="col text-right mt-1">
                        <button type="button" v-if="(index == showDivIndex && !editThis) || index != showDivIndex" class="btn btn-success boh1 mr-2" @click="setEdit(index)">Edit</button>
                        <button type="button" v-if="index == showDivIndex && editThis" class="btn btn-danger boh2 btn-sm" @click="setEditFalse(index)">Cancel</button>
                        <button type="button" v-if="index == showDivIndex && editThis" class="btn btn-success boh1 btn-sm" @click="updateFaq(faq)">Update</button>
                        <button type="button" v-if="index != showDivIndex || (index == showDivIndex && !editThis)" class="btn boh2 btn-danger btn-sm" @click.stop="showFaqModal(faq.id,index)">Delete</button>
                    </div>
                </div>
            </div>
            <div :class="index == showDivIndex ? 'col-12 d-block' : 'd-none'">
                <div class="row w-100 mt-2">
                    <div class="col-1 font-weight-bold" style="padding-top: 9px;">
                        <label>Answer:</label>
                    </div>
                    <div class="col-8 pr-0">
                        <textarea :readonly="!editThis" rows="5" :class="(index == showDivIndex && editThis) ? 'form-control' : 'bg-white form-control'" v-model="faq.answer"></textarea>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-3 mx-auto" v-if="totalPage > 1">
            <nav>
                <ul class="pagination">
                    <li :class="{'page-item': true, 'active' : this.currentPage === p }" v-for="p in totalPage" :key="p">
                        <a href="JavaScript:void(0);" class="page-link" @click="init(p)"> {{ p }} </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    
    <!-- Modal -->
    <div class="modal" :class="{  'd-block' : deleteModal }">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <p>Are you sure want to delete selected FAQ ?</p>
                </div>
                <div class="modal-footer d-flex justify-content-between">
                    <button type="button" class="btn btn-primary boh1" @click="deleteModal = false">CLOSE</button>
                    <button type="button" class="btn btn-danger boh2" @click="deleteFaq(dltFaq.id,dltFaq.index)">DELETE</button>
                </div>
            </div>
        </div>
    </div>
    <!-- delete faq ends -->


  </div>
</template>

<script>
// import f from "../../../helper/function";
import { Form, Field, ErrorMessage  } from 'vee-validate';
import * as yup from 'yup';
import API from "../../../api/http";
import { useToast } from "vue-toastification";

export default {
    name: 'FaqList',
    components: {
        Form, Field, ErrorMessage,
    },
    data(){
        const schema = yup.object().shape({
            question: yup.string().required().min(3),
            answer: yup.string().required().min(3),
        });

        return {
            page_title: 'FAQ Management',
            url: 'faq',
            loading: true,
            search: null,
            toast: useToast(),
            currentPage: 1,
            totalPage: null,
            showChildren: false,
            validationSchema: schema,
            faq: {
                question: null,
                answer: null,
                ordering: null,
            },
            faqCount: null,
            faqs: [],
            // readOnly: true,
            showDivIndex: -1,
            editThis: false,
            deleteModal: false,
            dltFaq: {
                id: null,
                index: null,
            }
        }
        
    },
    created(){
        this.init(this.currentPage);
    },
    methods: {
        init(page){
            // console.log(page);
            var url = this.url;
            this.currentPage = page;

            if(page > 1){
                url += (this.search) ? '&' : '?' + 'page=' + page;
            }
            if(this.search){
                url += (page > 1) ? '&' : '?' + 'search=' + this.search;
            }
            this.showDivIndex = -1
            this.editThis = false

            API.get(url).then((response) => {
                this.loading = false;
                this.faqs = response.faqs.data;
                this.totalPage = response.faqs.last_page;
                this.faqCount = response.faqs.total;
            })
        },
        showDiv (index) {
            console.log('show div called')
            if (this.showDivIndex != index) {
                this.editThis = false
            }
            if (this.showDivIndex == index && !this.editThis) {
                this.showDivIndex = -1
            } else {
                this.showDivIndex = index
            }
      
        },
        setEdit (index){
            this.showDivIndex = index
            this.editThis = true
        },
        setEditFalse (index) {
            this.showDivIndex = index
            this.editThis = false
        },
        submitFaq(){
            
            this.faq.ordering = this.faqCount + 1;
            API.post('faq',this.faq)
            .then((response) => {
                this.toast.success(response.message);
                this.faq = {
                    question: null,
                    answer: null,
                    ordering: null,
                }
                // this.$refs.form.reset();
                // this.errors.clear();
                this.init(1);
            })
            .catch((errors) => {
                this.toast.error(errors);
            })
        },
        updateFaq (faq) {
            console.log(faq)
            faq._method = "PUT";
            API.post('faq/' + faq.id,faq)
            .then((response)=>{
                this.toast.success(response.message);
                this.init(1)
            })
            .catch((error)=>{
                this.toast.success(error.message);
            });
        },
        showFaqModal(id,index){
            this.deleteModal = true;
            this.dltFaq.id = id;
            this.dltFaq.index = index;
        },
        deleteFaq(id,index){
            API.post('faq/' + id,{_method : 'DELETE'})
            .then((response) => {
                this.faqs.splice(index,1);
                this.deleteModal = false;
                this.faqCount = this.faqCount - 1;
                this.toast.success(response.message);
            })
            .catch((errors) => {
                this.toast.error(errors);
            })
        }
    }
}
</script>
