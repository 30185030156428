<template>
    <div class="page-container">
        <div class="header-wrap row">
            <div class="col-12"><h2 class="ml-2">{{ page_title }}</h2></div>
        </div>

        <div class="card">
            <div class="card-header">Edit User</div>
            <div class="card-body">
                <Form @submit="submitForm" :validation-schema="validationSchema" v-slot="{ errors }">

                    <div class="form-group row" name="name">
                        <label class="col-12 col-md-2">Name</label>
                        <div class="col-sm-10">
                            <Field type="text" name="name" 
                                :class="{'form-control':true,'is-invalid': errors.name}" 
                                placeholder="Name" v-model="user.name" />
                            <ErrorMessage name="name" class="invalid-feedback" />
                        </div>
                    </div>
                    <div class="form-group row" name="Business Name">
                         <label class="col-12 col-md-2">Business Name</label>
                         <div class="col-sm-10">
                            <Field type="text" name="business_name" :class="{'form-control':true,'is-invalid': errors.business_name}" 
                                placeholder="Business Name" v-model="user.business_name" />
                            <ErrorMessage name="business_name" class="invalid-feedback" />
                        </div>
                    </div>
                    <div class="form-group row" name="email">
                        <label class="col-12 col-md-2">Email</label>
                        <div class="col-sm-10">
                            <Field type="text" readonly name="email" id="email"
                                :class="{'form-control':true,'is-invalid': errors.email}" 
                                placeholder="Email Address" v-model="user.email" />
                            <ErrorMessage name="email" class="invalid-feedback" />
                        </div>
                    </div>
                    <div class="form-group row" name="industry_id">
                        <label class="col-12 col-md-2">Industry</label>
                        <div class="col-sm-10">
                            <Field v-model="user.industry_id" name="industry_id" id="industry_id" 
                                :class="{'form-control':true,'is-invalid': errors.industry_id }" as="select">
                                <option value="">Select Industry</option>
                                <option :value="industry.id" v-for="(industry,index) in industries" :key="index">{{ industry.name }}</option>
                            </Field>
                            <ErrorMessage name="industry_id"  class="invalid-feedback" />
                        </div>
                    </div>
                    <div class="form-group row" name="state_id">
                        <label class="col-12 col-md-2">State</label>
                        <div class="col-sm-10">
                            <Field v-model="user.state_id" name="state_id" id="state_id" class="border-2 border-black rounded py-1" 
                                :class="{'form-control':true,'is-invalid': errors.state_id }" as="select" @change="getCities">
                                <option value="" selected>Select State</option>
                                <option :value="index" v-for="(state,index) in states" :key="index">{{ state.name }}</option>
                            </Field>
                            <ErrorMessage name="state_id"  class="invalid-feedback" />
                        </div>
                    </div>
                    <div class="form-group row" name="city_id">
                        <label class="col-12 col-md-2">City</label>
                        <div class="col-sm-10">
                            <Field v-model="user.city_id" name="city_id" id="city_id" class="border-2 border-black rounded py-1" 
                                :class="{'form-control':true,'is-invalid': errors.state_id }" as="select" :disabled="!citiesLoaded">
                                <option value="" selected>Select City</option>
                                <option :value="city.id" v-for="(city,index) in cities" :key="index">{{ city.name }}</option>
                            </Field>
                            <ErrorMessage name="city_id"  class="invalid-feedback" />
                        </div>
                    </div>
                    <div class="form-group row" name="gender">
                        <label class="col-12 col-md-2">Gender</label>
                        <div class="col-sm-10">   
                            <Field v-model="user.gender_id"  name="gender_id" id="gender_id" class="border-2 border-black rounded py-1" 
                                :class="{'form-control':true,'is-invalid' :errors.gender_id }" as="select">
                                <option value="" selected>Select Gender</option>
                                <option :value="index" v-for="(gender,index) in genders" :key="index">{{ gender.name }}</option>
                            </Field>
                            <ErrorMessage name="gender_id"  class="invalid-feedback" />
                        </div>
                    </div>
                    <div class="form-group row" name="instagram">
                        <label class="col-12 col-md-2">Instagram</label>
                        <div class="col-sm-10">
                            <Field type="text" name="instagram" :class="{'form-control':true,'is-invalid': errors.instagram}" 
                                v-model="user.instagram" />
                            <ErrorMessage name="instagram"  class="invalid-feedback" />
                        </div>
                    </div>
                    <div class="form-group row" name="Website">
                        <label class="col-12 col-md-2">Website</label>
                        <div class="col-sm-10">
                            <Field type="url" name="website" :class="{'form-control':true,'is-invalid': errors.website}" 
                                v-model="user.website" />
                            <ErrorMessage name="website" class="invalid-feedback" />
                        </div>
                    </div>
                    <div class="form-group row" name="why_business">
                        <label class="col-12 col-md-2">Why Business</label>
                        <div class="col-sm-10">
                            <Field v-model="user.why_business" name="why_business" id="" type="input" rows="6" 
                                :class="{'form-control':true,'is-invalid': errors.why_business}" as="textarea" />
                            <ErrorMessage name="why_business"  class="invalid-feedback" />
                        </div>
                    </div>
                    
                    <div class="pt-3 border-top text-right">
                        <button class="btn btn-success ml-2" type="submit" @click="submitForm" :disabled="submitProcessing">
                            <span v-if="!submitProcessing">Save</span>
                            <span v-else>Please Wait...</span>
                        </button>
                        <router-link class="btn btn-warning ml-2" :to="{name: 'UserList'}" :disabled="submitProcessing">
                            <span v-if="!submitProcessing">Cancel</span>
                            <span v-else>Please Wait...</span>
                        </router-link>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
import API from "../../../api/http";
import { Form, Field, ErrorMessage  } from 'vee-validate';
import * as yup from 'yup';
import { useToast } from "vue-toastification";

export default {
    name: 'EditUser',
    components: {
        Form, Field, ErrorMessage,
    },
    computed:{
        currentUser(){
            return this.$store.getters.currentUser;
        },
        currentRouteName() {
            return this.$route.name;
        }
    },
    data(){
        const schema = yup.object().shape({
            name: yup.string().required().min(2),
            business_name: yup.string().required(),
            email: yup.string().required().email(),
            industry_id: yup.string().required(),
            state_id: yup.string().required(),
            city_id: yup.string().required(),
            geneder_id: yup.string().required(),
            website: yup.string().url(),
        });

        return {
            page_title: 'EditUser',
            user: {},
            validationSchema: schema,
            toast: useToast(),
            industries: [],
            states: [],
            cities: [],
            genders: [],
            citiesLoaded: false,
            submitProcessing: false,
            submit_close: false,
        }
    },
    created(){
        this.init();
    },
    methods: {
        init(){
            let url = 'user/' + this.$route.params.id;
            API.get(url)
            .then((response) => {
                this.user = response.user;
                this.industries = response.industries;
                this.states = response.states;
                this.cities = response.cities;
                this.genders = response.genders;
                this.submitProcessing = false;
            })
            .catch((error) => {
                console.log(error);
            })
        },
        getCities(){
            if(this.user.state_id){
                this.showLoading = true;
                this.submitProcessing = true;
                API.get('get-cities/' + this.user.state_id)
                .then((response) => {
                    this.cities = response.cities;
                    this.citiesLoaded = true;
                    this.submitProcessing = false;
                })
            }
        },
        // submitClose(){
        //     console.log('inside submit close')
        //     this.submit_close = true;
        //     var form = this.$refs.item_form
        //     form.click()
        // },
        submitForm(){
            console.log('hello')
            this.submitProcessing = true;
            var postURL = 'update-user/' + this.$route.params.id;

            console.log(postURL)

            API.post(postURL,this.user).then((response) => {
                this.toast.info(response.message);
                if(this.submit_close){
                    this.$router.push({ name: 'UserList' });
                }
                this.submitProcessing = false;
            }).catch((error) => {
                this.submitProcessing = false;
                console.log(error)
            });
        },
    }
}
</script>