<template>
  <div class="page-container">
    <div class="header-wrap row border-bottom mx-auto">
      <div class="col-6"><h2 class="">{{ page_title }}</h2></div>
      <div class="col-6">
          <router-link :to="{ name: 'FaqList'}" class="btn boh1 text-white btn-primary float-right">
              Back
          </router-link>
      </div>
    </div>

    <draggable v-model="faqs" @change="move">
        <transition-group>
            <div v-for="(faq) in faqs" :key="faq.id" class="px-4 py-3 rounded border my-2 mx-3">
            <div class="row">
                <div class="col">
                    {{faq.question}}
                </div>
                <div class="col text-right">
                    <i class="fad fa-sort-up"></i>
                </div>
            </div>
            </div>
        </transition-group>
    </draggable>

  </div>
</template>

<script>
// import f from "../../../helper/function";';
import { VueDraggableNext } from 'vue-draggable-next'
import API from "../../../api/http";
import { useToast } from "vue-toastification";

export default {
    name: 'FaqOrdering',
    components: {
        draggable: VueDraggableNext,
    },
    data(){
        return {
            page_title: 'Order FAQs',
            url: 'faq?list=1',
            loading: true,
            toast: useToast(),
            faqs: [],
        }
        
    },
    created(){
        this.init(this.url);
    },
    methods: {
        init(url){
            API.get(url).then((response) => {
                this.faqs = response.faqs;
            })
        },
        move() {
            API.post('order-faq', {faq: this.faqs} )
            .then(() => {
                this.init(this.url)
            })
        }
    }
}
</script>
