<template>
  <div class="page-container">
    <div class="header-wrap row border-bottom mx-auto">
      <div class="col-6"><h2 class="">{{ page_title }}</h2></div>
      <div class="col-6 text-right">
        <form @submit.prevent="init(1,true)" class="w-75 float-right">
          <div class="input-group">
            <input type="text" class="form-control" v-model="search" placeholder="Search">
            <div class="input-group-append">
              <button class="btn btn-success boh1 text-white btn-l" type="button" @click="init()">Search</button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <Form class="row mx-auto" @submit="submitClotheType" :validation-schema="validationSchema" v-slot="{ errors }" ref="form">
        <div class="col-10">
            <div class="row py-2 h-100">
                <div class="col-12 h-100">
                    <Field type="text" name="name" 
                        :class="{'form-control w-100':true,'is-invalid': errors.name }" 
                        placeholder="Clothe Type" v-model="clothe.name" />
                    <ErrorMessage name="name" class="invalid-feedback" />
                </div>
            </div>
        </div>
        <div class="col-2 d-flex justify-content-end align-items-center">
            <button class="btn btn-primary boh3 text-white w-50 justify-content-center" type="submit">
                Add New
            </button>
        </div>
    </Form>

    <div class="mt-4 px-3">
        <div class="row mx-auto bg-light py-2 rounded mt-3" v-for="(type,index) in clotheTypes" :key="index">
            <div class="col-12">
                <div class="row w-100">
                    <div class="col-1 font-weight-bold" style="padding-top: 9px;">
                        <label class="text-dark">Name:</label>
                    </div>
                    <div class="col-8 pr-0">
                        <div class="input-group h-100">
                            <input :readonly="(index == editThis ? false : true )" type="text" aria-describedby="basic-addon2" class="h-100 bg-white cursor-pointer form-control" v-model="clotheTypes[index].name">
                        </div>
                    </div>
                    <div class="col text-right mt-1">
                        <button v-if="(index == editThis && edit ? false : true )" type="button" class="btn btn-success boh1 btn-sm mr-2" @click="setEdit(index)">Edit</button>
                        <button v-if="(edit && index == editThis ? true : false )" type="button" class="btn btn-danger boh2 btn-sm mr-2" @click="cancelEdit(index)">Cancel</button>
                        <button v-if="(edit && index == editThis ? true : false )" type="button" class="btn btn-success boh1 btn-sm" @click="updateClotheType(type)">Update</button>
                        <button v-if="(index == editThis && edit ? false : true )" type="button" class="btn btn-danger boh2 btn-sm" @click.stop="showModal(type.id,index)">Delete</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
    
    <!-- Modal -->
    <div class="modal" :class="{  'd-block' : deleteModal }">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <p>Are you sure want to delete selected clothe type ?</p>
                </div>
                <div class="modal-footer d-flex justify-content-between">
                    <button type="button" class="btn boh1 btn-primary" @click="deleteModal = false">CLOSE</button>
                    <button type="button" class="btn boh2 btn-danger" @click="deleteClotheType(dltClotheType.id,dltClotheType.index)">DELETE</button>
                </div>
            </div>
        </div>
    </div>
    <!-- delete faq ends -->


  </div>
</template>

<script>
// import f from "../../../helper/function";
import { Form, Field, ErrorMessage  } from 'vee-validate';
import * as yup from 'yup';
import API from "../../../api/http";
import { useToast } from "vue-toastification";

export default {
    name: 'ClothTypeList',
    components: {
        Form, Field, ErrorMessage,
    },
    data(){
        const schema = yup.object().shape({
            name: yup.string().required().min(2)
        });

        return {
            page_title: 'Clothe Type Management',
            url: 'cloth-type',
            loading: true,
            search: null,
            toast: useToast(),
            validationSchema: schema,
            clotheTypes: [],
            clothe: {
                name: null,
            },
            edit: false,
            editThis: -1,
            deleteModal: false,
            dltClotheType: {
                id: null,
                index: null,
            }
        }
        
    },
    created(){
        this.init();
    },
    methods: {
        init(){
            // console.log(page);
            var url = this.url;

            if(this.search){
                url += '?search=' + this.search;
            }
            API.get(url).then((response) => {
                this.clotheTypes = response.clothe_types;
            })
        },
        setEdit (index){
            this.edit = true;
            this.editThis = index;
        },
        cancelEdit (index) {
            this.editThis = index;
            this.edit = false;
        },
        submitClotheType(){
            API.post('cloth-type',this.clothe)
            .then((response) => {
                this.toast.success(response.message);
                this.init(1);
            })
            .catch((errors) => {
                this.toast.error(errors);
            })
        },
        updateClotheType (type) {
            type._method = "PUT";
            API.post('cloth-type/' + type.id,type)
            .then((response)=>{
                this.editThis = -1;
                this.toast.success(response.message);
                this.init(1)
            })
            .catch((error)=>{
                this.toast.success(error.message);
            });
        },

        showModal(id,index){
            this.deleteModal = true;
            this.dltClotheType.id = id;
            this.dltClotheType.index = index;
        },
        deleteClotheType(id,index){
            API.post('cloth-type/' + id,{_method : 'DELETE'})
            .then((response) => {
                this.clotheTypes.splice(index,1);
                this.deleteModal = false;
                this.toast.success(response.message);
            })
            .catch((errors) => {
                this.toast.error(errors);
            })
        }
    }
}
</script>
